/**
 * Providers
 */
export const CASTELLUM = 'castellum';
export const THREAT_DIGITAL = 'threat-digital';
export const OPEN_CORPORATES = 'open-corporates';

/**
 * Type of checks
 */
export const SANCTIONS = 'sanctions';
export const PEPS = 'peps';
export const ADVERSE_MEDIA = 'adverse_media';
export const UBO = 'ubo';
export const CORPORATE_RECORDS_SEARCH = 'corporate_records_search';
export const CORPORATE_RECORDS_CHECK = 'corporate_records_check';
export const CORPORATE_OFFICER_SEARCH = 'corporate_officer_search';
export const CORPORATE_OFFICER_CHECK = 'corporate_officer_check';
export const MAXIMUM_SUBJECT_ALIASES = 20;

/**
 * Match types
 */
export const MATCH = 'MATCH';
export const NO_MATCH = 'NO_MATCH';
export const UNKNOWN = 'UNKNOWN';